<template>
    <div id="mailEvent">
        <page-title icon="fas fa-user-tie" heading="Mail templates" subheading="Manage mail templates" v-bind:options="[
                {title:'Delete',class:'btn-danger',icon:'fas fa-trash',event:()=>deleteTemplate(mailEvent),enabled:isSelected},
                {title:'Save',class:'btn-success',icon:'fas fa-save',event:save,enabled:isSelected},
                {class:'btn-dark',icon:'fas fa-sync-alt',event:refresh},
        ]"/>
        <md-card>
            <div class="card-header" style="overflow: hidden" >
                <span class="card-title m-0">{{msg("Mail templates")}}</span>

            </div>
            <md-card-content v-if="loaded === true">
                <div class="row">
                    <div class="col-3 mail-templates">
                        <div v-for="(me,group) in allMailEvents" v-bind:key="group">
                            <p style="width: 100%">{{msg(group == '' ? 'others' : group)}}
                                <md-button class="md-icon-button md-xmini" @click="newTemplate(group)">
                                    <md-icon>add</md-icon>
                                </md-button>
                            </p>
                            <ul>
                                <li class="mail-templates-title" :class="{empty:m.subject === '',active:mailEvent == m}" v-for="m in me" v-bind:key="m.id" @click="setTemplate(m)" style="min-height: 30px">
                                    {{m.subject}}
                                    <span @click="deleteTemplate(m)">
                                        <md-icon>delete</md-icon>
                                    </span>

                                </li>
                            </ul>
                        </div>
                        <div class="text-center">
                            <md-button class="md-icon-button md-xmini md-theme-default" id="addGroup">
                                <md-icon><i class="fas fa-plus"></i></md-icon>
                            </md-button>
                            <b-popover :show.sync="popups.addGroup" target="addGroup">
                                <template slot="title">
                                    {{msg('Add group')}}
                                    <span style="position: absolute;top: 8px;right: 5px;cursor: pointer" @click="$root.$emit('bv::hide::popover')"><i class="fas fa-times"></i></span>
                                </template>
                                <div class="p-3" style="width: 300px">
                                    <md-field>
                                        <md-input ref="newGroup" maxlength="150"></md-input>
                                        <span @click="addNewGroup"><i v-b-tooltip :title="msg('Add')" class="fas fa-save"></i></span>
                                    </md-field>
                                </div>
                            </b-popover>
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="row">
                            <div class="col-9">
                                <md-field>
                                    <label>{{msg("BCC")}}</label>
                                    <md-input v-model="mailEvent.bcc" :disabled="!isSelected"/>
                                </md-field>
                            </div>
                            <div class="col-3">
                                <md-field>
                                    <label>{{msg("Group")}}</label>
                                    <md-select v-model="mailEvent.group" :disabled="!isSelected">
                                        <md-option v-for="g in Object.keys(allMailEvents).filter(g => g!= '')" v-bind:key="g" v-bind:value="g">
                                            {{msg(g)}}
                                        </md-option>
                                    </md-select>
                                </md-field>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-9">
                                <md-field>
                                    <label>{{msg("Subject")}}</label>
                                    <md-input v-model="mailEvent.subject" :disabled="!isSelected"/>
                                </md-field>
                            </div>

                        </div>
                        <froala v-model="mailEvent.body"></froala>
                        <!--                        <vue-editor   v-model="mailEvent.body" style="min-height: 800px" :disabled="!isSelected"></vue-editor>-->


                    </div>
                </div>
            </md-card-content>
            <md-card-content v-else-if="error !== ''">
                <b-alert show variant="danger">
                    <i class="fas fa-exclamation-triangle mr-3"></i> <strong>{{error}}</strong>
                </b-alert>
            </md-card-content>
            <md-card-content v-else>
                <b-spinner label="Spinning"/>
                {{msg("Loading")}}
            </md-card-content>
        </md-card>

       <!-- <div class="mt-3">
            <div class="row">
               <div class="col-6">
                   <md-card>
                       <div class="card-header" style="overflow: hidden">
                           <span class="card-title m-0">{{msg("Mail event")}}</span>
                       </div>
                       <md-card-content v-if="loaded === true">

                       </md-card-content>
                       <md-card-content v-else-if="error !== ''">
                           <b-alert show variant="danger">
                               <i class="fas fa-exclamation-triangle mr-3"></i> <strong>{{error}}</strong>
                           </b-alert>
                       </md-card-content>
                       <md-card-content v-else>
                           <b-spinner label="Spinning"/>
                           {{msg("Loading")}}
                       </md-card-content>
                   </md-card>
               </div>

                <div class="col-6">
                    <md-card>
                        <div class="card-header" style="overflow: hidden">
                            <span class="card-title m-0">{{msg("Promotions")}}</span>

                        </div>
                        <md-card-content v-if="loaded === true">

                        </md-card-content>
                        <md-card-content v-else-if="error !== ''">
                            <b-alert show variant="danger">
                                <i class="fas fa-exclamation-triangle mr-3"></i> <strong>{{error}}</strong>
                            </b-alert>
                        </md-card-content>
                        <md-card-content v-else>
                            <b-spinner label="Spinning"/>
                            {{msg("Loading")}}
                        </md-card-content>
                    </md-card>
                </div>

            </div>

        </div>-->

    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";
     import PageTitle from "@/@core/components/page-title/PageTitle";

    export default {
        name: "MailEvent",
        components: {PageTitle},
        data() {
            return {
                loaded: false,
                error: '',
                mailEvents: [],
                mailEvent: {
                    bcc: "",
                    body: "",
                    id: 0,
                    name: null,
                    subject: "",
                },

                tableSort: {},
                selected: -1,
                selectedRows: [],
                //For multi selection box
                allRowsSelected: false,
                indeterminateRows: false,

                bulkChange: {statusId: 0, buId: 0, repId: 0},
                operations: {bulkChange: false},
                //Dialogs to show
                dialogs: {org: false},
                popups: {addGroup: false, tag: false}

            }
        },
        computed: {
            ...mapGetters('translator', ['msg']),
            ...mapGetters('mailEvent', ['getNames', 'getNameByIndex', 'getLangs', 'getMailEventByNameAndLanguage']),
            ...mapGetters('data', ['getLanguage']),
            allMailEvents() {
                if (this.mailEvents == null)
                    return {};
                return this.mailEvents.reduce((obj, mt) => {
                        let group = (mt.group != null ? mt.group : '');
                        if (!Array.isArray(obj[group]))
                            obj[group] = [];
                        obj[group].push(mt);
                        return obj;
                    },
                    {});
            },

            isSelected() {
                return this.loaded === true && this.selected >= 0;
            },
            tags() {
                return {
                    client: ['firstname', 'lastname', 'email', 'username', 'password', 'external'],
                    organization: ['org_name', 'org_phone'],
                    order: ['order_total'],
                    calendar: ['appointment'],
                    login: ['token'],
                    form:['f(formulaire id)_p(position question)'],
                };
            }
        },
        created() {
            this.$root.$on('crm::branch-updated', () => {
                this.refresh();
            });

            this.refresh();
        },
        methods: {
            ...mapActions('mailEvent', ['loadData', 'change', 'register', 'update']),
            ...mapActions('mailEvent', {_bulkChange: "bulkChange", _refresh: 'refresh'}),
            refresh() {
                let $this = this;
                this.loaded = false;
                this._refresh({}).then((mails) => {


                    $this.loaded = true;
                    $this.error = "";
                    $this.mailEvents = mails;
                    if(Array.isArray($this.mailEvents) && $this.mailEvents.length > 0)
                        $this.setTemplate($this.mailEvents[0])
                }, (e) => {
                    console.log("Mailevent.refresh() error", e);
                    this.error = e;
                });
            },
            cancel() {
                //TODO: reset object to defaukt
                this.resetObj();
                this.selected = -1;
            },
            resetObj() {
                this.mailEvent = {
                    bcc: "",
                    body: "",
                    id: 0,
                    group: null,
                    subject: "",
                };
            },
            newTemplate(group) {
                //this.mailEvent
                if (this.allMailEvents[group].findIndex(template => template.id <= 0) >= 0)
                    return console.log("New template in progress");

                let template = {bcc: "", body: "", id: 0, group: group, subject: '',};
                this.mailEvent = template;
                this.mailEvents.push(template);
            },
            setTemplate(m) {
                this.selected = m.id;
                this.mailEvent = m;
            },
            deleteTemplate(m) {
                console.log("deleteTemplate()", m)
                if (m.id < 1) {
                    let i = this.mailEvents.findIndex(mt => mt == m);
                    if (i < 0)
                        return console.error("Mail not found");
                    this.mailEvents.splice(i, 1);
                    if (this.mailEvent === m)
                        this.resetObj();
                }
                console.log("hi")

            },
            addNewGroup() {
                //Get group
                let group = this.$refs['newGroup'].$el.value;
                //Add template
                let template = {bcc: "", body: "", id: 0, group: group, subject: '',};
                this.mailEvent = template;
                this.mailEvents.push(template);
                //Close popover
                this.$refs['newGroup'].$el.value = "";
                this.$root.$emit('bv::hide::popover');

            },
            save() {
                if (this.mailEvent.id > 0)
                    this.updateTemplate();
                else this.createTemplate();

            },
            createTemplate() {
                let $this = this;
                this.register({mailEvent: this.mailEvent}).then((id) => {
                    this.mailEvent.id = id;
                    $this.refresh();
                }, () => {
                });
            },
            updateTemplate() {
                let $this = this;
                this.update({id: this.mailEvent.id, mailEvent: this.mailEvent}).then(() => {
                    $this.resetObj();
                    $this.refresh();
                }, () => {
                })
            },

        }
    }
</script>

<style>
    #mailEvent .ql-editor {
        min-height: 500px;
    }

    #mailEvent .mail-templates ul {
        list-style: none;
    }

    #mailEvent .mail-templates p {
        margin-bottom: 10px;
        line-height: 30px;
        font-weight: 600;
        /*border-bottom: 1px solid #ccc;*/
    }

    #mailEvent .mail-templates p button {
        float: right;
        cursor: pointer;
        margin-bottom: 5px;
    }

    #mailEvent .mail-templates ul li.mail-templates-title {
        line-height: 30px;
        cursor: pointer;
        /*padding-left: 10px;*/
        text-align: center;

    }

    #mailEvent .mail-templates ul li.mail-templates-title.empty {
        background-color: #cccccc96;
        border-radius: 5px;
        color: #fff;
    }

    #mailEvent .mail-templates ul li.mail-templates-title.active,
    #mailEvent .mail-templates ul li.mail-templates-title:hover {
        background-color: #ff6e40;
        border-radius: 5px;
        color: #fff;

    }

    #mailEvent .mail-templates ul li.mail-templates-title i {
        display: none;
        float: right;
        font-size: 18px !important;
        line-height: 22px;
    }

    #mailEvent .mail-templates ul li.mail-templates-title:hover i {
        display: inline-block;
        position: absolute;
        right: 18px;
        margin-top: 3px;
        color: #fff;
    }

    #mailEvent .mail-templates ul li.mail-templates-title:hover i:hover {
        /*border: 1px solid #ccc;*/
        font-size: 20px !important;
    }
</style>
